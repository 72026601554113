import { Container, Row, Col } from "react-bootstrap";
import instagram from "../assets/img/instagram.svg";
import linkedin from "../assets/img/linkedin.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6} className="padding-top-3">
          </Col>
          <Col
            size={12}
            sm={6}
            className="text-center text-sm-end padding-top-3"
          >
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/in/iamanasrashid"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="LinkedIN" />
              </a>
              <a
                href="https://www.instagram.com/photosbyanas/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="Instagram" />
              </a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
