import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";

export const Skills = () => {
  //TODO: need to use react media query to change the number of items to show
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>
                Brief list of my technical skills, <br></br>
                including programming languages, frameworks, databases, and
                tools I'm proficient in
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <i className="fa-brands fa-uncharted skill-icon  fa-3x"></i>
                  <h5>Software Development</h5>
                </div>
                <div className="item">
                  <i className="fa-solid fa-network-wired skill-icon fa-3x"></i>
                  <h5>Web Development</h5>
                </div>
                <div className="item">
                  <i className="fa-brands fa-square-js skill-icon fa-3x"></i>
                  <h5>JavaScript</h5>
                </div>
                <div className="item">
                  <i className="fa-brands fa-react skill-icon fa-3x"></i>
                  <h5>React</h5>
                </div>
                <div className="item">
                  <i className="fa-brands fa-sass skill-icon fa-3x"></i>
                  <h5>Sass/Less</h5>
                </div>
                <div className="item">
                  <i className="fa-solid fa-server skill-icon fa-3x"></i>
                  <h5>.NET Core</h5>
                </div>
                <div className="item">
                  <i className="fa-solid fa-database skill-icon fa-3x"></i>
                  <h5>SQL</h5>
                </div>
                <div className="item">
                  <i className="fa-brands fa-github skill-icon fa-3x"></i>
                  <h5>Git</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img
        className="background-image-left"
        src={colorSharp}
        alt="Background"
      />
    </section>
  );
};
